import React, { useState, useEffect, Fragment } from "react"

import "./Projects.css"

import { environment } from "../../../Environment/Environment"
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner"

//** Custom Hooks
import { useHttpClient } from "../../../shared/hooks/http-hook"

const Projects = () => {
  const { isLoading, sendRequest } = useHttpClient()
  const [projectsPageContent, setProjectsPageContent] = useState()
  const baseUrl = environment.baseUrl

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest("/projectsList")
        const pageContent = responseData ? responseData.data.projectsList : []
        if (pageContent) {
          setProjectsPageContent(pageContent)
        }
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [sendRequest])

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      <div id="content-portfolio">
        <div className="container">
          <h4>Projects</h4>
          <h2>Recently Completed</h2>

          <div className="portfolio-first-row">
            {!isLoading &&
              projectsPageContent &&
              projectsPageContent.map((d) => (
                <Fragment key={d.id}>
                  <div className="latest-projects-design mb-4">
                    <picture>
                      <img src={baseUrl + d.picture} alt="img" />
                    </picture>
                    <a
                      href={`http://${d.openUrl}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>{d.header}</button>
                    </a>
                    <h3>{d.paragraph}</h3>
                  </div>
                </Fragment>
              ))}
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Projects
