import React, { useState, useEffect } from "react"

import "./Intro.css"
import { environment } from "../../../Environment/Environment"

//** Custom Hooks
import { Link } from "react-router-dom"

const Intro = (props) => {
  const [introPageContent, setIntroPageContent] = useState()
  const baseUrl = environment.baseUrl

  const { isLoading, introPageData } = props

  useEffect(() => {
    setIntroPageContent(introPageData)
  }, [introPageData])

  return (
    <React.Fragment>
      {!isLoading && introPageContent && (
        <div className="container">
          {" "}
          <div className="intro-section">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-12 mobileImage mb-4">
                <div className="intro-image">
                  <img
                    src={baseUrl + introPageContent.introImage}
                    alt="img"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12">
                <div className="intro-content">
                  <h3>{introPageContent.welcomeTitle}</h3>
                  <h1
                    dangerouslySetInnerHTML={{
                      __html: introPageContent.header
                    }}
                  ></h1>
                  <p>{introPageContent.paragraph}</p>
                  <Link to="/contact" className="getInTouchButton btn">
                    Get In Touch
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 desktopImage">
                <div className="intro-image">
                  <img
                    src={baseUrl + introPageContent.introImage}
                    alt="img"
                    style={{ width: "100%" }}
                  />
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      )}
    </React.Fragment>
  )
}

export default Intro
