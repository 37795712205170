import React, { useState, useEffect, Fragment } from "react"

import "./Features.css"

import { environment } from "../../../Environment/Environment"
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner"

//** Custom Hooks
import { useHttpClient } from "../../../shared/hooks/http-hook"

const Features = () => {
  const { isLoading, sendRequest } = useHttpClient()
  const [skillsPageContent, setSkillsPageContent] = useState()
  const baseUrl = environment.baseUrl

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest("/skillsList")
        const pageContent = responseData ? responseData.data.skillsList : []
        if (pageContent) {
          setSkillsPageContent(pageContent)
        }
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [sendRequest])

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}
      <div id="content-features">
        <div className="container">
          <h4>Skills</h4>
          <h2>What I Do</h2>
          <div className="features-first-row">
            {!isLoading &&
              skillsPageContent &&
              skillsPageContent.map((d) => (
                <Fragment key={d.id}>
                  <div className="skills-box feature mb-4">
                    <picture>
                      <img src={baseUrl + d.picture} alt="img" />
                    </picture>
                    <h3>{d.header}</h3>
                    <p>{d.paragraph}</p>
                  </div>
                </Fragment>
              ))}
          </div>
          <div className="features-second-row"></div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Features
