import React, { useState, useEffect, Fragment } from "react"

import "./Resume.css"
import { environment } from "../../../Environment/Environment"
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner"

//** Custom Hooks
import { useHttpClient } from "../../../shared/hooks/http-hook"

import resume from '../../../assets/Naveen Raja S - Resume.pdf'

const Resume = () => {
  const { isLoading, sendRequest } = useHttpClient()
  const [educationContent, setEducationContent] = useState()
  const [experienceContent, setExperienceContent] = useState()

  const baseUrl = environment.baseUrl

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest("/educationList")

        const EducationContent = responseData
          ? responseData.data.educationList
          : []

        if (EducationContent) {
          setEducationContent(EducationContent)
        }
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
    const fetchEduData = async () => {
      try {
        const responseData = await sendRequest("/experienceList")

        const experienceContent = responseData
          ? responseData.data.experienceList
          : []

        if (experienceContent) {
          setExperienceContent(experienceContent)
        }
      } catch (err) {
        console.log(err)
      }
    }
    fetchEduData()
  }, [sendRequest])

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner asOverlay />}

      <div id="content-resume">
        <div className="container">
          <h4>Resume - <a href={resume} download target="_blank" rel="noopener noreferrer">Download</a></h4>
          <h2>Find My Information</h2>
          {!isLoading && educationContent && (
            <div className="inside-content">
              <div className="inside-content-left">
                <div className="content-resume-title">
                  <h3>Education</h3>
                </div>
                {educationContent &&
                  educationContent.map((d) => (
                    <Fragment key={d.id}>
                      <div className="ic-box">
                        <div className="info1">
                          <picture>
                            <img src={baseUrl + d.picture} alt="" />
                          </picture>
                          <h3>{d.organization}</h3>
                        </div>
                        <div className="info1">
                          <button>{d.year}</button>
                          <h4> - {d.header}</h4>
                        </div>
                        <p>{d.paragraph}</p>
                      </div>
                    </Fragment>
                  ))}
              </div>
              <div className="inside-content-right">
                <div className="content-resume-title">
                  <h3>Experience</h3>
                </div>
                {experienceContent &&
                  experienceContent.map((d) => (
                    <Fragment key={d.id}>
                      <div className="ic-box">
                        <div className="info1">
                          <picture>
                            <img src={baseUrl + d.picture} alt="" />
                          </picture>
                          <h3>{d.organization}</h3>
                        </div>
                        <div className="info1">
                          <button>{d.year}</button>
                          <h4> - {d.header}</h4>
                        </div>
                        <p>{d.paragraph}</p>
                      </div>
                    </Fragment>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Resume
