import React, { useState, useEffect } from "react"

import "./Contact.css"
import { useHttpClient } from "../../../shared/hooks/http-hook"

import contactImg from "../../../assets/img/contact.svg"
import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { isObjEmpty } from "../../../Utility/Utils"

import spinner from "../../../assets/img/spinner.gif"

const defaultValues = {
  fullname: "",
  email: "",
  phonenumber: "",
  message: ""
}

const Contact = () => {
  const { isLoading, sendRequest } = useHttpClient()
  const [submitSuccessMessage, setSubmitSuccessMessage] = useState()
  const [submitFailMessage, setSubmitFailMessage] = useState()

  const SignupSchema = yup.object().shape({
    fullname: yup.string().required(),
    email: yup.string().required(),
    phonenumber: yup.string().required(),
    message: yup.string().required()
  })

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues,
    resolver: yupResolver(SignupSchema)
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const onSubmit = (event) => {
    if (isObjEmpty(errors)) {
      const contactForm = async () => {
        try {
          const responseData = await sendRequest(
            "/contactSubmit",
            "POST",
            JSON.stringify({
              fullname: event.fullname,
              email: event.email,
              phonenumber: event.phonenumber,
              message: event.message
            }),
            {
              "Content-Type": "application/json"
            }
          )
          const apiSuccess = responseData.success === true
          if (apiSuccess) {
            setSubmitSuccessMessage("Sent successfully")
            reset(defaultValues)
            setTimeout(() => {
              setSubmitSuccessMessage("")
            }, 2500)
          }
        } catch (err) {
          console.log(err)
          setSubmitFailMessage("Something went wrong please try again later")
          setTimeout(() => {
            setSubmitFailMessage("")
          }, 3000)
        }
      }
      contactForm()
    }
  }

  return (
    <React.Fragment>
      <div id="content-contact">
        <div className="container">
          <h4>Contact Me</h4>
          <h2>Write Your Message</h2>
          <div className="contact-content">
            <div className="contact-image pb-2">
              <picture>
                <img src={contactImg} alt="img" style={{ width: "100%" }} />
              </picture>
            </div>
            <div className="send-message-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                  id="fullname"
                  name="fullname"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      name="fullname"
                      className={errors.fullname && `${"invalid"}`}
                      placeholder="Full Name*"
                      {...field}
                    />
                  )}
                />

                <Controller
                  id="email"
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="email"
                      name="email"
                      className={errors.email && `${"invalid"}`}
                      placeholder="Email Address*"
                      {...field}
                    />
                  )}
                />

                <Controller
                  id="phonenumber"
                  name="phonenumber"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="number"
                      name="phonenumber"
                      className={errors.phonenumber && `${"invalid"}`}
                      placeholder="Phone Number*"
                      {...field}
                    />
                  )}
                />

                <Controller
                  id="message"
                  name="message"
                  control={control}
                  render={({ field }) => (
                    <textarea
                      rows="3"
                      cols="82"
                      className={errors.message && `${"invalid"}`}
                      name="message"
                      placeholder="Your Message*"
                      {...field}
                    />
                  )}
                />

                {isLoading && (
                  <img className="form-spinner" src={spinner} alt="" />
                )}

                {!isLoading && submitSuccessMessage && (
                  <h4 className="text-center text-success pb-2 pt-4">
                    {submitSuccessMessage}
                  </h4>
                )}
                {!isLoading && submitFailMessage && (
                  <h4 className="text-center text-danger pb-2 pt-4">
                    {submitFailMessage}
                  </h4>
                )}

                <input type="submit" name="submit" value="Click here to Send" />
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Contact
