import React, { useState, useEffect } from "react"
import { NavLink } from "react-router-dom"

import "./Header.css"
import { environment } from "../../../Environment/Environment"

//** Custom Hooks
import { AiOutlineDoubleRight } from "react-icons/ai"

const Header = (props) => {
  const [headerContent, setHeaderContent] = useState()
  const baseUrl = environment.baseUrl
  const [showMobileNavbar, setShowMobileNavbar] = useState(false)

  const { headerData, isLoading } = props

  useEffect(() => {
    setHeaderContent(headerData)
  }, [headerData])

  const showMobileNav = () => {
    setShowMobileNavbar(!showMobileNavbar)
  }
  const closeMobileNav = () => {
    setShowMobileNavbar(!showMobileNavbar)
  }

  return (
    <React.Fragment>
      {!isLoading && headerContent && (
        <header>
          <div className="row">
            <div className="col-lg-5 col-md-5 col-12">
              <div className="profile-img">
                <img
                  className="img-thumbnail"
                  src={baseUrl + headerContent.profileImage}
                  alt="profile"
                />
                <div className="profile-detail">
                  <div className="profile-name">
                    <h2>{headerContent.name}</h2>
                  </div>
                  <div className="profile-skill">
                    <p>{headerContent.jobTitle}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-7 col-12">
              <div className="header-nav">
                <ul className="header-nav-list">
                  <li>
                    <NavLink to="/" exact>
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/resume">Resume</NavLink>
                  </li>
                  <li>
                    <NavLink to="/skills">Skills</NavLink>
                  </li>
                  <li>
                    <NavLink to="/projects">Projects</NavLink>
                  </li>

                  <li>
                    <NavLink to="/contact">Contact</NavLink>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mobile-nav text-center">
              <div className="mobile-nav-show" onClick={showMobileNav}>
                <AiOutlineDoubleRight />
              </div>
              {showMobileNavbar && (
                <div className="header-nav-list">
                  <ul>
                    <li onClick={closeMobileNav}>
                      <NavLink className="navLinkMobile" to="/" exact>
                        Home
                      </NavLink>
                    </li>
                    <li onClick={closeMobileNav}>
                      <NavLink className="navLinkMobile" to="/resume">
                        Resume
                      </NavLink>
                    </li>
                    <li onClick={closeMobileNav}>
                      <NavLink className="navLinkMobile" to="/skills">
                        Skills
                      </NavLink>
                    </li>
                    <li onClick={closeMobileNav}>
                      <NavLink className="navLinkMobile" to="/projects">
                        Projects
                      </NavLink>
                    </li>
                    <li onClick={closeMobileNav}>
                      <NavLink className="navLinkMobile" to="/contact">
                        Contact
                      </NavLink>
                    </li>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </header>
      )}
    </React.Fragment>
  )
}

export default Header
