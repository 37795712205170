export const environment = {
  apiUrl: 'https://master.skyisthelimitt.com/api/user',
  baseUrl: 'https://master.skyisthelimitt.com/',
};

// export const environment = {
//   apiUrl: 'http://localhost:4000/api/user',
//   baseUrl: 'http://localhost:4000/',
// };

