import React, { useState, useEffect } from "react"
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom"

import { useHttpClient } from "./shared/hooks/http-hook"
import LoadingSpinner from './shared/components/UIElements/LoadingSpinner'


import Features from "./Views/pages/Features/Features"
import Intro from "./Views/pages/Intro/Intro"
import Header from "./Views/Layout/Header/Header"
import Projects from "./Views/pages/Projects/Projects"
import Resume from "./Views/pages/Resume/Resume"
import Contact from "./Views/pages/Contact/Contact"
import Footer from "./Views/Layout/Footer/Footer"

function App() {
  const { isLoading, sendRequest } = useHttpClient()
  const [headerContent, setHeaderContent] = useState()
  const [introPageContent, setIntroPageContent] = useState()
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await sendRequest("/introList")
        const headerPageContent = responseData ? responseData.data.introList : []
        if (headerPageContent) {
          setHeaderContent(headerPageContent[0])
        }
        const introPageContent = responseData ? responseData.data.introList : []
        if (introPageContent) {
          setIntroPageContent(introPageContent[0])
        }
      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [sendRequest])

  let routes

  routes = (
    <Switch>
      <Route path="/" exact>
        <div className="portfolio-form">
          <Intro isLoading={isLoading} introPageData={introPageContent} />
        </div>
      </Route>
      <Route path="/skills" exact>
        <Features />
      </Route>
      <Route path="/projects" exact>
          <Projects />
      </Route>
      <Route path="/resume">
        <Resume />
      </Route>
      <Route path="/contact">
        <Contact />
      </Route>
      <Redirect to="/" />
    </Switch>
  )

  return (
    <Router>
      {isLoading && <LoadingSpinner asOverlay/>}
      {!isLoading && <div className="portfolio">
        <div className="portfolio-form">
          <Header isLoading={isLoading} headerData={headerContent}/>
        </div>
        <main>{routes}</main>
        <div className="portfolio-form">
          <Footer />
        </div>
      </div>}
    </Router>
  )
}

export default App
