import React from "react"

import "./Footer.css"

import facebook from "../../../assets/img/facebook.svg"
import twitter from "../../../assets/img/twitter.svg"
import instagram from "../../../assets/img/instagram.svg"
import linkedin from "../../../assets/img/linkedin.svg"

const Footer = () => {

  const currentYear = new Date().getFullYear()

  return (
    <React.Fragment>
      <footer>
        <div className="container">
          <div className="social-media-icons">
            <picture>
              <a
                href="https://www.facebook.com/profile.php?id=100000379348326"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <img src={facebook} alt="img" />
              </a>
            </picture>
            <picture>
              <a
                href="https://www.instagram.com/imnaveenn/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <img src={instagram} alt="img" />
              </a>
            </picture>
            <picture>
              <a
                href="https://twitter.com/NavEEn14647999?t=VvcqBr1eqAOvtFEaLrzzGg&s=08"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <img src={twitter} alt="img" />
              </a>
            </picture>
            <picture>
              <a
                href="https://www.linkedin.com/in/naveen-raja-s-867953212"
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                <img src={linkedin} alt="img" />
              </a>
            </picture>
          </div>
          <p>Copyright © {currentYear} | All Rights Reserved.</p>
        </div>
      </footer>
    </React.Fragment>
  )
}

export default Footer
